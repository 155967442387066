


























import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ListContactDataDto } from "@interface/contact-data";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EmployeeSelect extends Vue {
  @Prop({ required: true, type: String }) value!: string;
  @Prop({ default: false, required: false, type: Boolean }) disabled!: boolean;

  loading = false;
  options: Option<ListContactDataDto>[] = [];

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(new RequestQueryParams());
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllEmployee } = useContactData();
    this.loading = true;
    findAllEmployee(params)
      .then((res: Pagination<ListContactDataDto>) => {
        this.options = res.data.map(item => ({
          meta: item,
          label: item.employeePosition,
          key: item.id,
          value: item.id,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | null): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(e: string | null): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({ firstName: e || "", lastName: e || "" });

    this.fetchOptions(params);
  }

  findOption(value: string | null): Option<ListContactDataDto> | undefined {
    return this.options.find(item => item.value === value);
  }
}
