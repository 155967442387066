var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_invoice_ar") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form",
                    _vm._b(
                      { attrs: { layout: "vertical", form: _vm.form } },
                      "a-form",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 24, sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.invoiceType.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.invoiceType.decorator,
                                          expression:
                                            "formRules.invoiceType.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.invoiceType.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.invoiceType.placeholder
                                        ),
                                        "filter-option": false,
                                        loading: _vm.loading.invoiceType,
                                        allowClear: true,
                                        disabled: _vm.disabled.invoiceType
                                      }
                                    },
                                    _vm._l(_vm.dataInvoiceType, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.value) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.source.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.formRules.source.decorator,
                                          expression:
                                            "formRules.source.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.source.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.source.placeholder
                                        ),
                                        "filter-option": false,
                                        loading: _vm.loading.source,
                                        allowClear: true,
                                        disabled: _vm.disabled.source
                                      },
                                      on: {
                                        change: function(value) {
                                          return _vm.changeDropDown(
                                            value,
                                            "source"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataListSource, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.value) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.show.details
                                ? _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.details.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "dashed" },
                                          on: {
                                            click: function() {
                                              return _vm.handleViewDetail({
                                                name: "details",
                                                visible: true
                                              })
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("lbl_more")))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.invoiceDate.label
                                    )
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.invoiceDate.decorator,
                                        expression:
                                          "formRules.invoiceDate.decorator"
                                      }
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        _vm.formRules.invoiceDate.placeholder
                                      ),
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      "disabled-date": _vm.disabledDate,
                                      disabled: _vm.disabled.invoiceDate
                                    },
                                    on: {
                                      change: function(value) {
                                        return _vm.changeDropDown(
                                          value,
                                          "invoiceDate"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.branch.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.formRules.branch.decorator,
                                          expression:
                                            "formRules.branch.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.branch.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.branch.placeholder
                                        ),
                                        showSearch: true,
                                        "filter-option": false,
                                        loading: _vm.loading.branch,
                                        disabled: _vm.disabled.branch,
                                        allowClear: true
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getBranch(value)
                                        },
                                        change: function(value) {
                                          return _vm.changeDropDown(
                                            value,
                                            "branch"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataBranch, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.name) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.currency.label)
                                  }
                                },
                                [
                                  _c("SelectCurrency", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.currency.decorator,
                                        expression:
                                          "formRules.currency.decorator"
                                      }
                                    ],
                                    attrs: { disabled: _vm.disabled.currency },
                                    on: {
                                      change: function(value) {
                                        return _vm.changeCurrency(value)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.customerName.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.customerName
                                              .decorator,
                                          expression:
                                            "formRules.customerName.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.customerName.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.customerName.placeholder
                                        ),
                                        showSearch: true,
                                        disabled: _vm.disabled.customerName,
                                        "filter-option": false,
                                        loading: _vm.loading.customer,
                                        allowClear: true
                                      },
                                      on: {
                                        search: _vm.getCustomerName,
                                        change: function(value) {
                                          return _vm.changeDropDown(
                                            value,
                                            "customerName"
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCustomer, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.firstName) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.firstName) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.customerShipAddress.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.customerShipAddress
                                              .decorator,
                                          expression:
                                            "formRules.customerShipAddress.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRules.customerShipAddress
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.customerShipAddress
                                            .placeholder
                                        ),
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled:
                                          _vm.disabled.customerShipAddress
                                      },
                                      on: {
                                        change: function(value) {
                                          return _vm.changeCustomerShipAddress(
                                            value
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.dataCustomerShipAddress,
                                      function(data, index) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: index,
                                            attrs: { value: data.address }
                                          },
                                          [
                                            _c(
                                              "a-tooltip",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.address) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.address) +
                                                    " "
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.customerBillAddress.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.customerBillAddress
                                              .decorator,
                                          expression:
                                            "formRules.customerBillAddress.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRules.customerBillAddress
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.customerBillAddress
                                            .placeholder
                                        ),
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled:
                                          _vm.disabled.customerBillAddress
                                      }
                                    },
                                    _vm._l(
                                      _vm.dataCustomerBillAddress,
                                      function(data, index) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: index,
                                            attrs: { value: data.address }
                                          },
                                          [
                                            _c(
                                              "a-tooltip",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.address) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.address) +
                                                    " "
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.show.so,
                                      expression: "show.so"
                                    }
                                  ],
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.salesOrder.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.salesOrder.decorator,
                                          expression:
                                            "formRules.salesOrder.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.salesOrder.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.salesOrder.placeholder
                                        ),
                                        "show-search": "",
                                        "filter-option": false,
                                        disabled: _vm.disabled.salesOrder,
                                        loading: _vm.loading.salesOrder,
                                        mode: "multiple"
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getSalesOrder(value)
                                        },
                                        change: function(value) {
                                          return _vm.changeDataForm(value, "so")
                                        },
                                        deselect: function(e) {
                                          return _vm.onDeselectDoc(e, "SO")
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataSalesOrder, function(data) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: data.value,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.documentNumber
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.documentNumber) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.show.do,
                                      expression: "show.do"
                                    }
                                  ],
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.doNumber.label)
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.doNumber.decorator,
                                          expression:
                                            "formRules.doNumber.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.doNumber.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.doNumber.placeholder
                                        ),
                                        showSearch: true,
                                        "filter-option": false,
                                        loading: _vm.loading.doNumber,
                                        disabled: _vm.disabled.doNumber,
                                        mode: "multiple"
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.getDoNumber(value)
                                        },
                                        change: function(value) {
                                          return _vm.changeDataForm(value, "do")
                                        },
                                        deselect: function(e) {
                                          return _vm.onDeselectDoc(e, "DO")
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataDoNumber, function(data) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: data.value,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.documentNumber
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.documentNumber) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.grNumber.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.grNumber.decorator,
                                        expression:
                                          "formRules.grNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.grNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.grNumber.placeholder
                                      ),
                                      disabled: _vm.disabled.grNumber
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.poNumber.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.poNumber.decorator,
                                        expression:
                                          "formRules.poNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.poNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.poNumber.placeholder
                                      ),
                                      disabled: _vm.disabled.poNumber
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.taxCalculation.label
                                    )
                                  }
                                },
                                [
                                  _c("SelectTaxCalculation", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "taxCalculation",
                                          {
                                            initialValue: "",
                                            rules: [
                                              {
                                                required: !_vm.isTaxNone,
                                                message: _vm.$t(
                                                  "lbl_validation_required_error"
                                                )
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                    'taxCalculation',\n                    {\n                      initialValue: '',\n                      rules: [\n                        {\n                          required: !isTaxNone,\n                          message: $t('lbl_validation_required_error'),\n                        },\n                      ],\n                    },\n                  ]"
                                      }
                                    ],
                                    attrs: { disabled: !_vm.isRecurring },
                                    on: { change: _vm.onChangeTaxCalculation }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.termOfPayment.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.termOfPayment
                                              .decorator,
                                          expression:
                                            "formRules.termOfPayment.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name: _vm.formRules.termOfPayment.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.termOfPayment
                                            .placeholder
                                        ),
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        loading: _vm.loading.termOfPayment,
                                        allowClear: true,
                                        "show-search": "",
                                        disabled: _vm.disabled.termOfPayment
                                      }
                                    },
                                    _vm._l(_vm.dataTermOfPayment, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.value) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(data.value) + " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.isRecurring
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.disableButton.generate
                                      },
                                      on: { click: _vm.generateData }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lbl_generate")))]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 24, sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.invoiceNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.invoiceNumber.decorator,
                                        expression:
                                          "formRules.invoiceNumber.decorator"
                                      }
                                    ],
                                    attrs: {
                                      disabled: true,
                                      name: _vm.formRules.invoiceNumber.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.invoiceNumber.placeholder
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.accountingDate.label
                                    )
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.accountingDate
                                            .decorator,
                                        expression:
                                          "formRules.accountingDate.decorator"
                                      }
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        _vm.formRules.accountingDate.placeholder
                                      ),
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.invoiceReceiveDate.label
                                    )
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.invoiceReceiveDate
                                            .decorator,
                                        expression:
                                          "formRules.invoiceReceiveDate.decorator"
                                      }
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      disabled: true,
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      name:
                                        _vm.formRules.invoiceReceiveDate.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.invoiceReceiveDate
                                          .placeholder
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.typeCurrency &&
                                        _vm.typeCurrency !== "IDR",
                                      expression:
                                        "typeCurrency && typeCurrency !== 'IDR'"
                                    }
                                  ],
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.rate.label)
                                  }
                                },
                                [
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.rate.decorator,
                                        expression: "formRules.rate.decorator"
                                      }
                                    ],
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      name: _vm.formRules.rate.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.rate.placeholder
                                      ),
                                      disabled: _vm.disabled.rate,
                                      "allow-clear": "",
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      min: 0,
                                      precision: _vm.storeBaseDecimalPlace
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.receivableAccount.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.receivableAccount
                                              .decorator,
                                          expression:
                                            "formRules.receivableAccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRules.receivableAccount.name,
                                        placeholder: _vm.$t(
                                          _vm.formRules.receivableAccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "filter-option": false,
                                        loading: _vm.loading.receivableAccount,
                                        allowClear: true,
                                        disabled: _vm.disabled.receivableAccount
                                      },
                                      on: {
                                        search: _vm.searchReceivableAccount
                                      }
                                    },
                                    _vm._l(_vm.dataReceivableAccount, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " - " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " - " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.operatorName.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.operatorName.decorator,
                                        expression:
                                          "formRules.operatorName.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.formRules.operatorName.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.operatorName.placeholder
                                      ),
                                      disabled: _vm.disabled.operatorName
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.assignee.label)
                                  }
                                },
                                [
                                  _c("SelectEmployee", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.assignee.decorator,
                                        expression:
                                          "formRules.assignee.decorator"
                                      }
                                    ],
                                    attrs: {
                                      disabled: _vm.disabled.assignee,
                                      name: _vm.formRules.assignee.name
                                    },
                                    on: { "update:meta": _vm.onChangeEmployee }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.usageType.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.usageType.decorator,
                                        expression:
                                          "formRules.usageType.decorator"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        _vm.formRules.usageType.placeholder
                                      ),
                                      name: _vm.formRules.usageType.name,
                                      disabled: _vm.disabled.usageType
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.invoiceDescription.label
                                    )
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "extra",
                                      fn: function() {
                                        return [
                                          _c("character-length", {
                                            attrs: {
                                              value:
                                                _vm.form.getFieldValue(
                                                  "invoiceDescription"
                                                ) || ""
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("a-textarea", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.invoiceDescription
                                            .decorator,
                                        expression:
                                          "formRules.invoiceDescription.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name:
                                        _vm.formRules.invoiceDescription.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.invoiceDescription
                                          .placeholder
                                      ),
                                      rows: 4,
                                      disabled: _vm.disabled.invoiceDescription
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.statusInvoice.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.statusInvoice.decorator,
                                        expression:
                                          "formRules.statusInvoice.decorator"
                                      }
                                    ],
                                    attrs: {
                                      disabled: "",
                                      name: _vm.formRules.statusInvoice.name,
                                      placeholder: _vm.$t(
                                        _vm.formRules.statusInvoice.placeholder
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.journalNumber.id
                                ? _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.journalNumber.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "generaljournal.detail",
                                              params: {
                                                id: _vm.journalNumber.id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            { attrs: { type: "link" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.journalNumber.name)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "mt-2", attrs: { span: 24 } },
                [
                  _c(
                    "a-tabs",
                    {
                      attrs: {
                        "default-active-key": _vm.activeTabPane,
                        activeKey: _vm.activeTabPane,
                        type: "card"
                      },
                      on: { change: _vm.handleChangeMenu }
                    },
                    _vm._l(_vm.columnTabPane, function(item) {
                      return _c(
                        "a-tab-pane",
                        { key: item },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "tab" }, slot: "tab" },
                            [
                              _vm._v(" " + _vm._s(item) + " "),
                              _c("a-icon", {
                                staticStyle: { color: "red" },
                                attrs: { type: "tag" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form",
                            _vm._b(
                              {
                                staticStyle: {
                                  "padding-left": "1rem",
                                  "padding-right": "1rem"
                                },
                                attrs: {
                                  layout: "vertical",
                                  form: _vm.formTable
                                }
                              },
                              "a-form",
                              _vm.formItemLayout,
                              false
                            ),
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.activeTabPane === "Details",
                                      expression: "activeTabPane === 'Details'"
                                    }
                                  ],
                                  staticClass: "pt-4"
                                },
                                [
                                  _c("TabDetails", {
                                    attrs: {
                                      dataSourceDetailsTabpane:
                                        _vm.dataSourceDetailsTabpane,
                                      showConfirmation: _vm.showConfirmation,
                                      selectedRowKeysDetails:
                                        _vm.selectedRowKeysDetails,
                                      onSelectChangeDetails:
                                        _vm.onSelectChangeDetails,
                                      handleSelectTableDetails:
                                        _vm.handleSelectTableDetails,
                                      disabled: _vm.disabled,
                                      handleInputDetails:
                                        _vm.handleInputDetails,
                                      dataCustomerShipAddress:
                                        _vm.dataCustomerShipAddress,
                                      disableButton: _vm.disableButton,
                                      getCheckboxPropsDetail:
                                        _vm.getCheckboxPropsDetail,
                                      disabledTableColumn:
                                        _vm.disabledTableColumn,
                                      changeDataInput: _vm.changeDataInput,
                                      changeSource: _vm.changeSource,
                                      "detail-invoice": _vm.detailDataInvoiceAr,
                                      handleSwitchDetails: _vm.onChangeSwitch,
                                      handleInputNumberDetails:
                                        _vm.handleInputNumberDetails
                                    },
                                    on: {
                                      "on-view-more": _vm.onViewMore,
                                      dataTaxCode: _vm.dataTaxCode,
                                      "on-add-row": _vm.onAddRow
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.activeTabPane === "Tax Details",
                                      expression:
                                        "activeTabPane === 'Tax Details'"
                                    }
                                  ],
                                  staticClass: "pt-4"
                                },
                                [
                                  _c("TabTax", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      loading: _vm.loading,
                                      changeCustomerTax: _vm.changeCustomerTax,
                                      changeCheckbox: _vm.changeCheckbox,
                                      onBlur: _vm.onBlur,
                                      onFocus: _vm.onFocus
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.activeTabPane ===
                                        "Apply Prepayment",
                                      expression:
                                        "activeTabPane === 'Apply Prepayment'"
                                    }
                                  ],
                                  staticClass: "pt-4"
                                },
                                [
                                  _c("TabPrepayment", {
                                    attrs: {
                                      dataSourceApplyPrepaymentTabpane:
                                        _vm.dataSourceApplyPrepaymentTabpane,
                                      "page-size":
                                        _vm.queryParams.invoicePrepayment.limit,
                                      onSelectChangeApplyPrepayment:
                                        _vm.onSelectChangeApplyPrepayment,
                                      reset: _vm.resetPrepayment,
                                      loading: _vm.loading
                                    },
                                    on: {
                                      "on-refresh": _vm.onRefreshPrepayment,
                                      change: _vm.onChangeTablePrepayment,
                                      delete: _vm.onDeletePrepayment
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.activeTabPane === "Status",
                                      expression: "activeTabPane === 'Status'"
                                    }
                                  ],
                                  staticClass: "pt-4"
                                },
                                [
                                  _c("TabStatus", {
                                    on: { "on-view": _vm.handleViewDetail }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("back")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                      ),
                      _vm.allowCancel && _vm.$can("cancel", "invoice-ar")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: { confirm: _vm.handleCancelInvoice }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.cancel
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.allowPostJournal && _vm.$can("update", "invoice-ar")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: { confirm: _vm.handlePostJournal }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.loading.postJournal
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("lbl_post_journal")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.show.print && _vm.$can("read", "invoice-ar")
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handlePrint }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.update && _vm.$can("update", "invoice-ar")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.update
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("update")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.submit && _vm.$can("update", "invoice-ar")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.submit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.$can("update", "invoice-ar")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: { confirm: _vm.handeRejectInvoice }
                              },
                              [
                                _vm.allowReject
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          loading: _vm.loading.reject
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_reject")) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 720,
            title:
              _vm.titleModal === "prepaymentUsed"
                ? "Detail Prepayment Used"
                : _vm.titleModal === "creditMemo"
                ? "Detail Credit Memo"
                : _vm.titleModal === "paidStatus"
                ? "Detail Paid Status"
                : "Details",
            showmodal: _vm.modalOpenDetail,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table2",
                  "row-key": function(r, i) {
                    return i
                  },
                  dataSource:
                    _vm.titleModal === "prepaymentUsed"
                      ? _vm.dataSourceModalPrepayment
                      : _vm.titleModal === "creditMemo"
                      ? _vm.dataSourceModalCreditMemo
                      : _vm.titleModal === "paidStatus"
                      ? _vm.dataSourceModalPaidStatus
                      : _vm.dataSourceDetails,
                  columns:
                    _vm.titleModal === "prepaymentUsed"
                      ? _vm.columnsTableModalPrepayment
                      : _vm.titleModal === "creditMemo"
                      ? _vm.columnsTableModalCreditMemo
                      : _vm.titleModal === "paidStatus"
                      ? _vm.columnsTableModalPaidStatus
                      : _vm.columnsTableModalDetails,
                  scroll: { x: "calc(100px + 50%)", y: 400 },
                  paginationcustom: false,
                  defaultPagination: true
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleCancelModalDetail } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 900,
            title: _vm.$t("lbl_details"),
            showmodal: _vm.modal.row.show,
            forceRender: true,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    layout: "vertical",
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 14 },
                    form: _vm.formDetailModal
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_product_code") } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.productCode.decorator,
                                    expression:
                                      "formRules.productCode.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_product_code"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_product_name") } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.productName.decorator,
                                    expression:
                                      "formRules.productName.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_product_code"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_product_brand") } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.productMerk.decorator,
                                    expression:
                                      "formRules.productMerk.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_product_brand"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_product_qty") } },
                            [
                              _c("a-input-number", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.productQty.decorator,
                                    expression: "formRules.productQty.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t("lbl_product_qty"),
                                  autocomplete: "off",
                                  disabled: true,
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 0,
                                  precision: _vm.storeBaseDecimalPlace
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_product_uom") } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.productUom.decorator,
                                    expression: "formRules.productUom.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_product_uom"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isShowBillingPeriod,
                                  expression: "isShowBillingPeriod"
                                }
                              ],
                              attrs: { label: _vm.$t("lbl_billing_period") }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.billingPeriodModal
                                        .decorator,
                                    expression:
                                      "formRules.billingPeriodModal.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_billing_period"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_price") } },
                            [
                              _c("a-input-number", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.price.decorator,
                                    expression: "formRules.price.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t("lbl_price"),
                                  autocomplete: "off",
                                  disabled: true,
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 0,
                                  precision: _vm.storeBaseDecimalPlace
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_discount") } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "d-inline-block w-50 mb-0 pb-0"
                                },
                                [
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["percentDiscount"],
                                        expression: "['percentDiscount']"
                                      }
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "lbl_discount_percent"
                                      ),
                                      disabled: _vm.disabled.discountPercent,
                                      formatter: _vm.formatterPercent,
                                      parser: _vm.reverseFormatPercent,
                                      min: 0,
                                      precision: _vm.storeBaseDecimalPlace
                                    },
                                    on: {
                                      change: function(value) {
                                        return _vm.changeDataInput(
                                          value || 0,
                                          "modalDiscountPercent"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "d-inline-block w-50 mb-0 pb-0"
                                },
                                [
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["discountValue"],
                                        expression: "['discountValue']"
                                      }
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "lbl_discount_amount"
                                      ),
                                      disabled: _vm.disabled.discountValue,
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      min: 0,
                                      precision: _vm.storeBaseDecimalPlace
                                    },
                                    on: {
                                      change: function(value) {
                                        return _vm.changeDataInput(
                                          value || 0,
                                          "modalDiscountAmount"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formDetailModal.getFieldValue(
                                    "includePPh"
                                  ),
                                  expression:
                                    "formDetailModal.getFieldValue('includePPh')"
                                }
                              ],
                              attrs: { label: _vm.$t("lbl_income_tax_rate") }
                            },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "d-inline-block w-50 mb-0 pb-0"
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.incomeTaxRatePercent
                                            .decorator,
                                        expression:
                                          "formRules.incomeTaxRatePercent.decorator"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "lbl_income_tax_rate_percent"
                                      ),
                                      autocomplete: "off",
                                      "addon-after": "%",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "d-inline-block w-50 mb-0 pb-0"
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isModeCreate,
                                        expression: "!isModeCreate"
                                      },
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.incomeTaxRateValue
                                            .decorator,
                                        expression:
                                          "formRules.incomeTaxRateValue.decorator"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "lbl_income_tax_rate_amount"
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.$t("lbl_base_amount_dpp") } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.baseAmount.decorator,
                                    expression: "formRules.baseAmount.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t("lbl_base_amount_dpp"),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.cancel } },
                [_vm._v(_vm._s(_vm.$t("lbl_ok")))]
              )
            ],
            1
          )
        ]
      ),
      _c("InvoicePrintModal", {
        attrs: { "doc-id": _vm.docId },
        model: {
          value: _vm.modalPrint.show,
          callback: function($$v) {
            _vm.$set(_vm.modalPrint, "show", $$v)
          },
          expression: "modalPrint.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }